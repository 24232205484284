import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './App.css';

import Posts from './Posts';

import './App.css';

function IndexPosts(props) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { index } = useParams()

  useEffect(() => {
    // Reset state when index changes
    setPosts([]);
    setLoading(true);
  
    window.scrollTo(0, 0);
    const tag = index.replace(/ /g, "-");
  
    fetch('https://public-api.wordpress.com/rest/v1/sites/indexfinger771404303.wordpress.com/posts?tag=' + tag)
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.posts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setLoading(false); // Avoid infinite loading state
      });
  }, [index]);


  if (loading) {
    return <div className="loading">loading...</div>
  }

  return (
    <Posts posts={posts} />
  )

}

export default IndexPosts
